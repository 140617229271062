







































// Components
import { Component, Vue } from 'vue-property-decorator';
import PieChart from '@/components/charts/pieChart';
import { getComponent } from '@/utils/helpers';

// GraphQL
// import dashboardAppsyncClient from '@/lib/appsync/dashboard';
// import getAutomationResults from '@/graphql/getAutomationResults.graphql';

// Interfaces
import AutomationTestChartData from '@/interfaces/automationTestChartData'; // eslint-disable-line
import PieChartData from '@/interfaces/pieChartData';

// Dates
// import { formatDate } from '@/utils/dateHelpers';

import { namespace } from 'vuex-class';
import MainTimeOption from '@/interfaces/mainTimeOption'; // eslint-disable-line
// import { addMinutes, formatISO, startOfDay } from 'date-fns';

const appModule = namespace('appModule');

@Component({
  components: {
    PieChart,
    GeneralLayout: () => getComponent('common/GeneralLayout'),
  },
})
export default class DailySummary extends Vue {
  @appModule.Getter
  public currentTimeOption!: MainTimeOption

  public automationTestsCharts: AutomationTestChartData[] = [];

  public unitTestsCharts: {
    label: string,
    chartData: PieChartData,
  }[] = [];

  public pieChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    legend: {
      display: false,
    },
  };

  public loading: boolean = false;

  public async fetchData(): Promise<void> {
    this.loading = true;
    /*
      Fetch automation results
     */
    // const {
    //   data:
    //     {
    //       get_automation_results: {
    //         results: automationResults,
    //       },
    //     },
    // } = await dashboardAppsyncClient.query({
    //   query: getAutomationResults,
    //   variables: {
    //     env: 'dev',
    //     start_date:
    //       formatISO(startOfDay(addMinutes(
    //         new Date(), new Date().getTimezoneOffset() - this.currentTimeOption.value * 1440,
    //       ))),
    //     end_date: formatISO(startOfDay(addMinutes(new Date(), new Date().getTimezoneOffset()))),
    //   },
    // });
    this.unitTestsCharts = [];
    for (let i = 0; i < 6; i += 1) {
      this.unitTestsCharts.push({
        label: ['tasq-assign-user-tasqs', 'tasq-api', 'tasq-celery-worker',
          'tasq-service', 'tasq-unit', 'tasq-worker-api', 'tasq-assign-service'][i],
        chartData: {
          labels: ['Passed', 'Failed', 'Skipped'],
          datasets: [
            {
              backgroundColor: [
                '#4B9C1D',
                '#F10227',
                '#F8E700',
              ],
              data: [
                10,
                1,
                1,
              ],
              borderWidth: 0.5,
              borderAlign: 'inner',
            },
          ],
        },
      });
    }
    // this.automationTestsCharts = automationResults.map((i, idx) => {
    //   const parsedItem = JSON.parse(i);
    //
    //   return {
    //     time: formatDate(parsedItem.time, 'MMM Do'),
    //     chartData: {
    //       labels: ['Passed', 'Failed', 'Skipped'],
    //       datasets: [
    //         {
    //           backgroundColor: [
    //             '#4B9C1D',
    //             '#F10227',
    //             '#F8E700',
    //           ],
    //           data: [
    //             parsedItem.result.filter((r) => r.result === 'PASS').length,
    //             parsedItem.result.filter((r) => r.result === 'FAIL').length,
    //             parsedItem.result.filter((r) => r.result === 'SKIPPED').length,
    //           ],
    //           borderWidth: 0.5,
    //           borderAlign: 'inner',
    //         },
    //       ],
    //     },
    //   };
    // });

    this.loading = false;
  }

  async created() {
    await this.fetchData();
    this.$eventBus.$on('timeAppChanged', () => {
      this.fetchData();
    });
  }
}
